import React, { useEffect, useState } from "react";
import cx from "classnames";

import GroupsTable from "./components/GroupsTable";
import ScoresTable from "./components/ScoresTable";

import {
  GetGroupsResponse,
  Group,
  SubmitGroupsData,
} from "../../interfaces/Groups";
import {
  GetScoresResponse,
  Score,
  SubmitScoresData,
} from "../../interfaces/Scores";
import { fetchGroups, fetchScores, rewriteUrl } from "../../helpers";
import Axios, { AxiosError } from "axios";
import { Show, ShowResponse, ShowsResponse } from "../../interfaces/Scrape";

const getGroupKey = (group: Group) => `${group.name}-${group.class}`;
const getScoreKey = (score: Score) => {
  const sanitizedSchool = score.school.replace(/\([^)]+\)/g, "").trim();
  return `${sanitizedSchool}-${score.score}-${score.showName}`;
};

export const Update: React.FC = () => {
  const [groupData, setGroupData] = useState<SubmitGroupsData | undefined>({
    groups: [],
  });
  const [scoreData, setScoreData] = useState<SubmitScoresData | undefined>({
    scores: [],
  });
  const [currentTab, setCurrentTab] = useState<"groups" | "scores">("groups");
  const [existingScores, setExistingScores] =
    useState<GetScoresResponse["classes"]>();
  const [existingGroups, setExistingGroups] =
    useState<GetGroupsResponse["groups"]>();
  const [isGetDisabled, setIsGetDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [submitGroupSuccess, setSubmitGroupSuccess] = useState("");
  const [submitScoreSuccess, setSubmitScoreSuccess] = useState("");
  const [submitGroupsError, setSubmitGroupsError] = useState<string | null>(
    null
  );
  const [submitScoresError, setSubmitScoresError] = useState<string | null>(
    null
  );

  const year = new Date().getFullYear().toString();
  const fetchAndSetExistingScores = async () => {
    const data = await fetchScores(year);
    setExistingScores(data.classes);
  };
  const fetchAndSetExistingGroups = async () => {
    const data = await fetchGroups(year);
    setExistingGroups(data.groups);
  };

  const existingGroupsHash = Object.values(existingGroups || []).reduce(
    (acc, cur) => {
      const key = getGroupKey(cur);
      acc[key] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );

  const unsavedGroups = groupData?.groups.filter(
    (group) => !(getGroupKey(group) in existingGroupsHash)
  );

  const existingScoresHash = Object.values(existingScores || []).reduce(
    (acc, cur) => {
      cur.scores.forEach((score) => {
        const key = getScoreKey(score);
        acc[key] = true;
      });

      return acc;
    },
    {} as Record<string, boolean>
  );

  const unsavedScores = scoreData?.scores.filter((score) => {
    const key = getScoreKey(score);
    return !(key in existingScoresHash);
  });

  const getGroupsFromShow = async (show: Show) => {
    const { data: showData } = await Axios.get<ShowResponse>(
      `https://wgiserver.org/v1/innerceapi=pvk7syNgSZbCyTtTf_tWAw?Division=Perc&ShowId=${show["show-id-key"]}`
    );
    const firstKey = Object.keys(showData.Results)[0];
    return showData.Results[firstKey]["Round 0"].map((group) => {
      const converted: Group = {
        name: group.backend_Group_Friendly_Name__c,
        class: group.Division_Class__c,
        location: group.backend_Group_Location__c,
      };
      return converted;
    });
  };

  const getScoresFromShow = async (show: Show) => {
    const showDate = new Date(show.ShowDate);
    const scores: Score[] = [];
    const showDateString = `${
      showDate.getMonth() + 1
    }/${showDate.getDate()}/${showDate.getFullYear()}`;
    const { data: showData } = await Axios.get<ShowResponse>(
      `https://wgiserver.org/v1/showapi=pvk7syNgSZbCyTtTf_tWAw?Division=Perc&ShowId=${show["show-id-key"]}`
    );
    const classes = Object.keys(showData.Results);
    classes.forEach((className) => {
      const roundKeys = Object.keys(showData.Results[className]);
      const lastKey = roundKeys[roundKeys.length - 1];
      const convertedScores = showData.Results[className][lastKey].map(
        (score) => {
          const converted: Score = {
            school: score.backend_Group_Friendly_Name__c,
            class: className.replace("Percussion", "").trim(),
            showDate: showDateString,
            score: score.gss_cs_score__c || "",
            showName: show.EventName.replace("2024 WGI ", ""),
            location: score.backend_Group_Location__c,
            inChampionships: null,
          };
          return converted;
        }
      );
      scores.push(...convertedScores);
    });

    return scores;
  };

  const getSubmitGroupsData = async () => {
    const { data: showsForGroupsData } = await Axios.get<ShowsResponse>(
      "https://wgiserver.org/v1/entriesapi=pvk7syNgSZbCyTtTf_tWAw?Division=perc&SeasonId=a0sUy0000007hTFIAY"
    );
    const shows = showsForGroupsData.filter((show) =>
      show.EventName.includes("Championships")
    );

    const groupsPromises = shows.map(getGroupsFromShow);
    const groupsResult = await Promise.all(groupsPromises);
    const groups = groupsResult.flat();
    const submitData: SubmitGroupsData = {
      groups,
      year: new Date().getFullYear().toString(),
      success: `Received ${groups.length} groups`,
    };
    return submitData;
  };

  const getSubmitScoresData = async (groups: Group[]) => {
    const { data: showsForScoresData } = await Axios.get<ShowsResponse>(
      "https://wgiserver.org/v1/seasonapi=pvk7syNgSZbCyTtTf_tWAw?Division=perc&SeasonId=a0sUy0000007hTFIAY"
    );
    const scoresPromises = showsForScoresData.map(getScoresFromShow);
    const scoresResult = await Promise.all(scoresPromises);
    let scores = scoresResult.flat();
    const groupHash = groups.reduce((acc, cur) => {
      const key = getGroupKey(cur);
      acc[key] = true;
      return acc;
    }, {} as Record<string, boolean>);

    scores = scores.map((score) => {
      const key = getGroupKey({
        name: score.school,
        class: score.class,
      } as unknown as Group);
      if (key in groupHash) {
        score.inChampionships = "true";
      }
      return score;
    });
    const submitScoresData: SubmitScoresData = {
      scores,
      year: new Date().getFullYear().toString(),
      success: `Received ${scores.length} scores`,
    };
    return submitScoresData;
  };

  const onGetGroupsAndScores = async () => {
    setIsGetDisabled(true);
    const submitGroupsData = await getSubmitGroupsData();
    const submitScoresData = await getSubmitScoresData(submitGroupsData.groups);
    setGroupData(submitGroupsData);
    setSubmitGroupSuccess(submitGroupsData.success ?? "");
    setScoreData(submitScoresData);
    setSubmitScoreSuccess(submitScoresData.success ?? "");
    setIsGetDisabled(false);
  };

  const submitGroups = async () => {
    setIsSubmitDisabled(true);
    setSubmitGroupSuccess("");
    setSubmitGroupsError(null);
    try {
      const { data } = await Axios.post(
        rewriteUrl("/api/submitGroups.php"),
        groupData
      );
      setSubmitGroupSuccess(data.success);
    } catch (e) {
      setSubmitGroupsError(
        `${(e as AxiosError).response?.data || (e as AxiosError).message}`
      );
    } finally {
      setIsSubmitDisabled(false);
    }
  };

  const submitScores = async () => {
    setIsSubmitDisabled(true);
    setSubmitScoreSuccess("");
    setSubmitScoresError(null);
    try {
      const { data } = await Axios.post(
        rewriteUrl("/api/submitScores.php"),
        scoreData
      );
      setSubmitScoreSuccess(data.success);
    } catch (e) {
      setSubmitScoresError(
        `${(e as AxiosError).response?.data || (e as AxiosError).message}`
      );
    } finally {
      setIsSubmitDisabled(false);
    }
  };

  const onSubmitGroupsAndScores = async () => {
    setIsSubmitDisabled(true);
    const promises = [submitGroups(), submitScores()];
    await Promise.all(promises);
    setIsSubmitDisabled(false);
  };

  useEffect(() => {
    fetchAndSetExistingGroups();
    fetchAndSetExistingScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container my-3">
      <h2>Update</h2>
      <a href="/">Back</a>
      <div className="d-flex flex-column" style={{ gap: "8px" }}>
        <button
          className="btn btn-success btn-block"
          disabled={isGetDisabled}
          type="button"
          onClick={onGetGroupsAndScores}
        >
          Get Groups and Scores
        </button>
        <button
          className="btn btn-success btn-block"
          disabled={isSubmitDisabled}
          type="button"
          onClick={onSubmitGroupsAndScores}
        >
          Submit Groups and Scores
        </button>

        {submitGroupSuccess && (
          <div className="alert-success p-2">{submitGroupSuccess}</div>
        )}
        {submitGroupsError && (
          <div className="alert-danger p-2">{submitGroupsError}</div>
        )}
        {submitScoreSuccess && (
          <div className="alert-success p-2">{submitScoreSuccess}</div>
        )}
        {submitScoresError && (
          <div className="alert-danger p-2">{submitScoresError}</div>
        )}
        <div>
          <div className="d-flex" style={{ gap: "4px" }}>
            <button
              className={cx("btn", currentTab === "groups" && "btn-primary")}
              type="submit"
              onClick={() => setCurrentTab("groups")}
            >
              Groups
            </button>
            <button
              className={cx("btn", currentTab === "scores" && "btn-primary")}
              type="submit"
              onClick={() => setCurrentTab("scores")}
            >
              Scores
            </button>
          </div>
          <div className="p-4 border">
            {currentTab === "groups" && (
              <GroupsTable data={groupData} unsaved={unsavedGroups} />
            )}
            {currentTab === "scores" && (
              <ScoresTable data={scoreData} unsavedScores={unsavedScores} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
