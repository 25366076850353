import axios from "axios";
import { FC, useCallback, useEffect, useState } from "react";

import { fetchScores, formatDate, rewriteUrl } from "../helpers";

import About from "./About";
import Header from "./Header";
import ScrollToTop from "./ScrollToTop";
import YearSelect from "./YearSelect";
import { GetScoresResponse } from "../interfaces/Scores";
import { GroupsSelect } from "./GroupsSelect";
import { TableClass } from "./TableClass";
import { VerticalClass } from "./VerticalClass";

export const Home: FC = () => {
  const url = new URL(window.location.href);
  const searchYear = url.searchParams.get("year");
  const searchIsVertical = url.searchParams.get("isVertical");
  const searchShowAllGroups = url.searchParams.get("showAllGroups") === "true";

  const [year, setYear] = useState(searchYear || "2025");
  const [data, setData] = useState<GetScoresResponse | undefined>();
  const [isVertical, setIsVertical] = useState(searchIsVertical === "true");
  const [showAllGroups, setShowAllGroups] = useState(searchShowAllGroups);
  // GroupsSelect data exists for 2023+ seasons
  const showGroupsSelect = parseInt(year, 10) >= 2023;

  const getScores = async (y: string) => {
    setData(undefined);
    try {
      const data = await fetchScores(y);
      setData(data);
    } catch (e) {
      console.error(`Error getting scores ${e}`);
    }
  };

  const setQuery = (key: string, value: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState(null, "", url);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setYear(value);
    setQuery("year", value);
  };

  const handleVerticalChange = (value: boolean) => {
    setIsVertical(value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer.push(
      "event",
      "send",
      "ToggleVerticalButton",
      "click",
      isVertical ? "timeline" : "table",
      isVertical
    );
    setQuery("isVertical", value.toString());
  };

  const handleGroupsChange = useCallback(
    (value: boolean) => {
      if (value === searchShowAllGroups) {
        return;
      }

      setShowAllGroups(value);
      setQuery("showAllGroups", `${value}`);
    },
    [searchShowAllGroups]
  );

  const renderYears = () => {
    if (!data) return null;

    const { classes, lastUpdated, years } = data;

    return years ? (
      <div className="container-fluid" id="rankings">
        <div className="d-block d-sm-flex justify-content-between border border-radius p-3 section-header">
          <h2>
            Rankings for {showAllGroups ? "All" : "WGI-Bound"} Groups
            <span className="last-updated">
              (updated {formatDate(lastUpdated)})
            </span>
          </h2>

          <div className="d-flex justify-content-between">
            <YearSelect onChange={handleYearChange} year={year} years={years} />
            {showGroupsSelect && (
              <GroupsSelect
                onChange={(e) => handleGroupsChange(e.target.value === "true")}
                showAllGroups={showAllGroups ? "true" : "false"}
              />
            )}

            <div className="btn-group">
              <button
                className={`outline-none btn oi oi-spreadsheet btn-${
                  !isVertical ? "secondary" : "light"
                }`}
                onClick={() => {
                  handleVerticalChange(false);
                }}
              />
              <button
                className={`btn oi oi-list btn-${
                  isVertical ? "secondary" : "light"
                }`}
                onClick={() => {
                  handleVerticalChange(true);
                }}
              />
            </div>
          </div>
        </div>

        <div className="border border-top-0">
          <div>
            {Object.keys(classes).map((c) => {
              const classObj = classes[c];
              const scores = classObj.scores.filter(
                (score) => showAllGroups || score.inChampionships
              );

              return isVertical ? (
                <VerticalClass
                  key={c}
                  scores={scores}
                  name={c}
                  classObj={classObj}
                />
              ) : (
                <TableClass
                  key={c}
                  scores={scores}
                  name={c}
                  classObj={classObj}
                />
              );
            })}
          </div>
        </div>

        <ScrollToTop />
      </div>
    ) : (
      <p className="loading">Loading scores...</p>
    );
  };

  useEffect(() => {
    getScores(year);
  }, [year]);

  useEffect(() => {
    handleGroupsChange(showGroupsSelect ? searchShowAllGroups : true);
  }, [handleGroupsChange, searchShowAllGroups, showGroupsSelect]);

  return (
    <div>
      <Header />
      <div className="content mb-3 p-3">
        <About />
        {renderYears()}
      </div>
      <div className="footer">&copy; 2018 wgirankings.com</div>
    </div>
  );
};

export default Home;
