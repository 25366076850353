import React from "react";

export interface GroupsSelectProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  showAllGroups: string;
}

export const GroupsSelect: React.FC<GroupsSelectProps> = ({
  onChange,
  showAllGroups,
}) => {
  const values = [
    { label: "WGI-bound", value: "false" },
    { label: "All", value: "true" },
  ];
  return (
    <form className="form-inline mr-3">
      <label htmlFor="yearSelect">
        <select
          className="form-control p-2"
          name="year-select"
          id="yearSelect"
          value={showAllGroups}
          onChange={onChange}
        >
          {values.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </label>
    </form>
  );
};
